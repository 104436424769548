import React, { Component } from 'react'
import { Link } from "gatsby"

import imgActso from "../../assets/images/gallery-icons/gallery-award-actso.jpg"
import imgAmericanVisions from "../../assets/images/gallery-icons/gallery-award-americanvisions.jpg"
import imgAmericanVoices from "../../assets/images/gallery-icons/gallery-award-americanvoices.jpg"
import imgBig from "../../assets/images/gallery-icons/gallery-award-big.jpg"
import imgCivic from "../../assets/images/gallery-icons/gallery-award-civic.jpg"
import imgGold from "../../assets/images/gallery-icons/gallery-award-gold.jpg"
import imgGoldPort from "../../assets/images/gallery-icons/gallery-award-goldport.jpg"
import imgGoldPortWriting from "../../assets/images/gallery-icons/gallery-writing-poetry.png"
import imgHerblock from "../../assets/images/gallery-icons/gallery-award-herblock.jpg"
import imgNspp from "../../assets/images/gallery-icons/gallery-award-nspp.jpg"
import imgNyLife from "../../assets/images/gallery-icons/gallery-award-nylife.jpg"
import imgOneEarth from "../../assets/images/gallery-icons/gallery-award-oneearth.jpg"
import imgSilver from "../../assets/images/gallery-icons/gallery-award-silver.jpg"
import imgSilverDistinction from "../../assets/images/gallery-icons/gallery-award-silverdistinction.jpg"
import imgSilverDistinctionWriting from "../../assets/images/gallery-icons/gallery-writing-novel.png"
import imgSciFi from "../../assets/images/gallery-icons/gallery-writing-scifi.png"
import imgFlauntit from "../../assets/images/gallery-icons/gallery-flauntit.png"

export default class ByAwards extends Component {
    render() {

        const CATEGORIESNATIONAL = [ 
            {
                icon: imgGold ,
                title: "Gold Medal",
                query: "B"
            },
            {
                icon: imgSilver ,
                title: "Silver Medal",
                query: "C"
            },
            {
                icon: imgAmericanVisions ,
                title: "American Visions",
                query: "W"
            },
            {
                icon: imgAmericanVoices ,
                title: "American Voices",
                query: "V"
            }
        ]

        const CATEGORIESSCHOLARSHIPS = [
                {
                    icon: imgGoldPort ,
                    title: "Gold Medal Art Portfolio",
                    query: "BPA"
                },
                {
                    icon: imgGoldPortWriting ,
                    title: "Gold Medal Writing Portfolio",
                    query: "BPW"
                },
                {
                    icon: imgSilverDistinction ,
                    title: "Silver Medal with Distinction Art Portfolio",
                    query: "DA"
                },
                {
                    icon: imgSilverDistinctionWriting ,
                    title: "Silver Medal with Distinction Writing Portfolio",
                    query: "DW"
                },
                // {
                //     icon: imgActso ,
                //     title: "Alliance/ACT-SO Journey Award",
                //     query: "0"
                // },
                // {
                //     icon: imgBig ,
                //     title: "Best-in-Grade Award",
                //     query: "E"
                // },
                // {
                //     icon: imgCivic ,
                //     title: "Civic Expression Award",
                //     query: "6"
                // },
                {
                    icon: imgHerblock ,
                    title: "The Herblock Award for Editorial Cartoon",
                    query: "3"
                },
                {
                    icon: imgNspp ,
                    title: "National Student Poets Program",
                    query: "Z"
                },
                {
                    icon: imgNyLife ,
                    title: "New York Life Award",
                    query: "L"
                },
                {
                    icon: imgFlauntit ,
                    title: "The Flaunt It Award",
                    query: "#"
                }
                // {
                //     icon: imgOneEarth ,
                //     title: "One Earth Award",
                //     query: "8"
                // },
                // {
                //     icon: imgSciFi ,
                //     title: "Ray Bradbury Award for Science Fiction & Fantasy",
                //     query: "RBA_AWARD"
                // }
            ]

        return (

                <div id="gallery">
{ /*<h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3>*/}
<h2>By Awards</h2>

                <section id="gallery-categories">
                    <h3>National Medals</h3>
                  <ul>
                  {CATEGORIESNATIONAL.map((cat, index) => (
                      <li key={index}>
                      
                      <Link to="/gallery" state= {{ workType: "awards", category: cat.title, query: cat.query, year: "2023"  }}>
                          <span className="thumb">
                            <img
                              src={cat.icon}
                              width="120"
                              height="120"
                              alt={cat.title}
                            />
                          </span>
                          <span className="name">{cat.title}</span>
                        </Link>
                        
                      </li>
                    ))}
                  </ul>
                </section>
                
        
                <section id="gallery-categories">
                <h3>Scholarship Awards</h3>
                  <ul>
                  {CATEGORIESSCHOLARSHIPS.map((cat, index) => (
                      <li key={index}>
                      
                      <Link to="/gallery" state= {{ workType: "awards", category: cat.title, query: cat.query , year: "2023"}}>
                          <span className="thumb">
                            <img
                              src={cat.icon}
                              width="120"
                              height="120"
                              alt={cat.title}
                            />
                          </span>
                          <span className="name">{cat.title}</span>
                        </Link>
                        
                      </li>
                    ))}
                  </ul>
                </section>

            </div>
        )
    }
}