import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import { Scrollbars } from "react-custom-scrollbars"
import { AiOutlineCloseCircle } from "react-icons/ai"
import Share from "../share-gallery"
import parse from "html-react-parser"

const SingleWork = props => {

  const { title, work, students } = props.location.state

  const [schools, setSchools] = useState([])
  const [portWork, setPortWork] = useState([])

  const [hasError, setHasError] = useState(false)

  const getSchools = work => {
    let noHome = work.schools.filter(function (school) {
      return school.name !== "Home School"
    })
    setSchools(noHome)
  }

  useEffect(() => {

    let fetchSchools = async () => {
      try {
        getSchools(work)
      } catch (error) {
        setHasError(true)
        throw new Error(error)
      }
    }
    fetchSchools()
  }, [])


  const handleClick = e => {
    const singleWork = work.portfolioWorks.find(o => o.id === e.target.id)

    setPortWork(singleWork)
  }

  const handleClickArt = e => {
    const singleWork = work.portfolioWorks[e.target.id]

    setPortWork(singleWork)
  }
  // debugger
  return (
    <div id="gallery-work">
      <div id="gallery-work-contents">
        <div id="gallery-work-label">
          <div className="label-data">
            {students.length > 1 || !work.personPhotoUrl ? (
              <div className="data-artist-no-thumb"></div>
            ) : (
              <div className="data-artist-thumb">
                <img
                  src={work.personPhotoUrl}
                  alt={`Profile Photo: ${work.personName}`}
                />
              </div>
            )}
            <div className="label-data-titles">
              <div className="data-title">{title}</div>

              <div className="data-artist-name">By {students.join(", ")}</div>
            </div>
          </div>
          <div id="close-alert" className="alert" onClick={props.close}>
            <AiOutlineCloseCircle
              size="1.4em"
              fill="white"
              title="Close Dialog"
            />
          </div>
        </div>
        <div className="art-box">
          {work.categoryName === "Video Game Design" ? (
            <div className="art-box-work">
              <div className="art-box-image">
                <img
                  src={work.assetUrl}
                  alt={`${work.title} by ${work.personName}`}
                />
              </div>
            </div>
          ) : work.assetType === "video" ? (
            <div className="art-box-work">
              <div className="art-box-image">
                <ReactPlayer
                  url={work.assetUrl}
                  controls
                  playing
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                        onContextMenu: e => e.preventDefault(),
                      },
                    },
                  }}
                />
              </div>
            </div>
          ) : work.portfolioWorks && work.isArt ? (
            <div className="art-box-work">
              <div className="art-box-image">
                {portWork.length !== 0 ? (
                  <div style={{ display: "flex", flexDirection: "column", margin: "0 auto" }}>
                    {portWork.title}
                    <img
                      className="art-box-image"
                      src={portWork.assetUrl}
                      alt={`${portWork.title} by ${portWork.personName}`}
                    />
                  </div>
                ) : (
                  <div>
                    {work.portfolioWorks[0].title}
                    <img
                      className="art-box-image"
                      src={work.portfolioWorks[0].assetUrl}
                      alt={`${work.portfolioWorks[0].title} by ${work.portfolioWorks[0].personName}`}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : work.isArt === true ? (
            <div className="art-box-work">
              <div className="art-box-image">
                <img
                  className="art-box-image"
                  src={work.assetUrl}
                  alt={`${work.title} by ${work.personName}`}
                />
              </div>
            </div>
          ) : work.portfolioWorks && work.isWriting && work.isPortfolio ? (
            <div className="writing-box-work">
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                // Duration for hide animation in ms.
                autoHideDuration={300}
                autoHeight
                autoHeightMin={400}
                autoHeightMax={800}
              // style={{ height: 600 }}
              >

                <div>
                  <p>{parse(portWork.title)}</p>
                  <p>{parse(portWork.content)}</p>
                </div>

                {/* {portWork.length !== 0 ? (
                  <div>
                    <p>{parse(portWork.title)}</p>
                    <p>{parse(portWork.content)}</p>
                  </div>
                ) : (
                  <div>
                    <p>{parse(work.titleContent)}</p>
                    {parse(work.content)}
                  </div>
                )} */}
              </Scrollbars>
            </div>
          ) : !work.isPortfolio ? (
            <div className="writing-box-work">
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                // Duration for hide animation in ms.
                autoHideDuration={300}
                autoHeight
                autoHeightMin={400}
                autoHeightMax={800}
              // style={{ height: 600 }}
              >
                {parse(work.content)}
              </Scrollbars>
            </div>
          ) : null}

          <div className="details">
            <div className="detail-groups scrollbars">
              <div className="detail-group">
                <strong>{students.join(", ")}</strong>
                <ul>
                  {students.length > 1 ? null : (
                    <li>Grade {work.personGrade}</li>
                  )}



                  {schools.map((school, index) => {
                    return (
                      <span key={index}>
                        <li>{school.name}</li>
                        <li>{school.address.slice(0, -5)}</li>
                      </span>
                    )
                  })}
                </ul>
              </div>
              <div className="detail-group">
                {work.teachers.length > 1 ? (
                  <strong>Educators</strong>
                ) : (
                  <strong>Educator</strong>
                )}
                {work.teachers.map((teacher, index) => {
                  return (
                    <ul key={index}>
                      <li>{teacher}</li>
                    </ul>
                  )
                })}
              </div>
              <div className="detail-group">
                {work.awards.length > 1 ? (
                  <strong>Awards</strong>
                ) : (
                  <strong>Award</strong>
                )}
                <div>{work.categoryName}</div>
                {work.awards.map((award, index) => {
                  return (
                    <ul key={index}>
                      <li>{award}</li>
                    </ul>
                  )
                })}
              </div>

              {work.portfolioWorks && work.isWriting ? (
                <div className="detail-group">
                  <strong>Portfolio Works</strong>

                  {work.portfolioWorks.map((portWork, index) => {
                    return (
                      <ul key={index}>
                        <li>
                          <span
                            className="port-thumb-writing"
                            id={portWork.id}
                            onClick={handleClick}
                          >
                            {portWork.title}
                          </span>
                        </li>
                      </ul>
                    )
                  })}
                </div>
              ) : null}

              {work.portfolioWorks && work.isArt ? (
                <div className="detail-group">
                  <strong>Portfolio Works</strong>
                  <div className="port-thumbs-grid">
                    {work.portfolioWorks.map((portWork, index) => {
                      return (
                        <div className="port-thumb-image">
                          <img
                            key={index}
                            id={index}
                            src={portWork.assetUrl}
                            alt={`${portWork.title} by ${portWork.personName}`}
                            onClick={handleClickArt}
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <Share location={props.location} title={title} students={students} />
    </div>
  )
}

export default SingleWork
