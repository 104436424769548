import React, { Component } from "react"
import { navigate } from "@reach/router"

export default class GallerySearch extends Component {
  state = {
    workType: "",
    category: "",
    year: "2023",
    query: "",
  }

  handleQueryChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit = e => {
    e.preventDefault()

    navigate("/gallery", {
      state: {
        workType: "query",
        category: this.state.query,
        query: this.state.query,
        year: this.state.year,
      },
    })
  }

  render() {
    return (
      <div id="gallery-grid">
{/* <h3 class="alert" id="alert">🚧  The gallery is currently in maintenance mode 🚧 <br />
           Check back here on Monday, June 5 to view the 2023 Awarded works. <br />
           Thank you for your patience.</h3> */}
        <div id="search">
          <section id="search-form" className="page-pad">
            
{/* <h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3> */}

            <h2>Search the Awards</h2>

 <form onSubmit={this.handleSubmit}>
              <div className="search-fields-container">
   
            
              <select className="search-form-item" value={this.state.year}
              onChange={this.handleQueryChange}
              id="year"
              name="year"
              >
                <option value="2023">2023</option>
                <option value="2022">2022</option>
                <option value="2021">2021</option>
                <option value="2020">2020</option>
                <option value="2019">2019</option>
                <option value="2018">2018</option>
                <option value="2017">2017</option>
                <option value="2016">2016</option>
                <option value="2015">2015</option>
                <option value="2014">2014</option>
                <option value="2013">2013</option>
                <option value="2012">2012</option>
                <option value="2011">2011</option>
                <option value="2010">2010</option>
              </select>
   
            
         
     
              </div>

              <div className="form-item">
                <input
                  type="text"
                  name="query"
                  placeholder="Search by Student Name or Work Title"
                  onChange={this.handleQueryChange}
                  required
                />
              </div>

              <div className="form-submit">
                <input type="submit" value="Search" className="button" />
              </div>

            </form> 

          </section>
        </div>
      </div>
    )
  }
}
