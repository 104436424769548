import React, { Component } from "react"
import GalleryGrid from "./gallery-grid"
export default class GalleryContainer extends Component {
  state = {
    workType: "",
    category: "",
    year: "2023",
    query: "",
  }

  renderSubtitle = props => {
    if (props.category) {
      if (props.workType === "query") {
        return (
          <React.Fragment>
            <h3 className="gallery-search-string">
              Search for "{props.category}" in {props.year}:{" "}
            </h3>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <div>
              <h2>{props.category}</h2>
            </div>
          </React.Fragment>
        )
      }
    } else {
      return <h2>Gallery</h2>
    }
  }

  componentDidMount() {
    if (this.props.location.state !== null) {
      this.setState({
        workType: this.props.location.state.workType,
        category: this.props.location.state.category,
        query: this.props.location.state.query,
        year: this.props.location.state.year,
      })
    }
  }

  render() {
    const isCategory = !Object.values(this.state).every(o => o === "")

    if (isCategory) {
      return (
        <div id="gallery-grid">

          {/* old alert text! */}
          {/* <h3 class="alert" id="alert">🚧  The gallery is currently in maintenance mode 🚧 <br />
           Check back here on Thursday, June 8 to view the 2023 Awarded works. <br />
           Thank you for your patience.</h3> */}

           {/* 2024 alert text */}
           <h3 class='alert' id='alert'>2024 Award-winning works are coming to the gallery soon!</h3>
           <br />

          <React.Fragment>
            <this.renderSubtitle
              category={this.state.category}
              workType={this.state.workType}
              year={this.state.year}
              query={this.state.query}
            />
          </React.Fragment>
          <GalleryGrid
            workType={this.state.workType}
            category={this.state.category}
            year={this.state.year}
            query={this.state.query}
          />
        </div>
      )
    } else {
      return (
        <div id="gallery-grid">

  {/* <h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3> */}

          <React.Fragment>
            <this.renderSubtitle
              category={this.state.category}
              workType={this.state.workType}
              year={this.state.year}
              query={this.state.query}
            />
          </React.Fragment>
          <GalleryGrid workType={""} category={""} year={""} query={""} />
        </div>
      )
    }
  }
}
