import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import { Scrollbars } from "react-custom-scrollbars"
import { AiOutlineCloseCircle } from "react-icons/ai"
import parse from "html-react-parser"

import GoldPortWriting from "../../assets/data/writingPortsGold"
import SilverDistinctPortWriting from "../../assets/data/writingPortsSilverDistinction"

import { navigate } from "@reach/router"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import LoadingSpinner from "../loading-spinner"

import "@reach/dialog/styles.css"

import Share from "../share-gallery"

const SingleWorkRoute = props => {
 
  // const goldIds = [
  //   "549647",
  //   "551317",
  //   "551318",
  //   "550106",
  //   "551408",
  //   "550334",
  //   "550631",
  //   "551834",
  // ]

  // const silverIds = [
  //   "549913",
  //   "550110",
  //   "550358",
  //   "550389",
  //   "550971",
  //   "550972",
  //   "550973",
  //   "550502",
  //   "550590",
  //   "550983",
  //   "550669",
  //   "550982",
  //   "550675",
  //   "550981",
  //   "550841",
  // ]



  const [work, setWork] = useState({})
  const [title, setTitle] = useState("")
  const [students, setStudents] = useState([])
  const [schools, setSchools] = useState([])

  const [hasError, setHasError] = useState(false)

  const [portWork, setPortWork] = useState({})

  // const updateStaticWorks = value => {
  //   const data = value.find(x => x.id === props.id)

  //   setWork(data)
  //   setTitle(data.title)
  //   setStudents([data.personName])
  // }

  const getStudents = data => {
    let winners = [data.personName].concat(data.collaborators)
    setStudents(winners)
  }

  const getSchools = data => {
    let noHome = data.schools.filter(function (school) {
      return school.name !== "Home School"
    })
    setSchools(noHome)
  }

  useEffect(() => {

      let fetchWork = async () => {
        try {
          const resp = await fetch(process.env.GATSBY_ORS_GALLERY + props.id)
          const data = await resp.json()

          setTitle(data.title)
          getStudents(data)
          getSchools(data)

          if (data.portfolioWorks) {
            setPortWork(data.portfolioWorks[0])
            setWork(data)
          } else {
            setWork(data)
          }
        } catch (error) {
          setHasError(true)
          throw new Error(error)
        }
      }
      fetchWork()
    }, [])

  const handleClick = e => {
    const singleWork = work.portfolioWorks.find(
      o => o.id === Number(e.target.id)
    )

    setPortWork(singleWork)
  }

  const handleClickArt = e => {

    const singleWork = work.portfolioWorks[e.target.id]

    setPortWork(singleWork)
  }



  return (
    <div id="gallery">
      {!work.schools ? (
        <section id="gallery-grid">
          <LoadingSpinner />
        </section>
      ) : hasError ? (
        <div id="gallery-work">Error occured.</div>
      ) : (
        <div id="gallery-work">
          <DialogOverlay
            isOpen={true}
            onDismiss={() => {
              navigate("/gallery/")
            }}
            style={{ background: "hsla(0, 0%, 0%, 0.85)", zIndex: 1 }}
          >
            <DialogContent
              aria-label="Single Work Dialog."
              style={{
                background: "none",
                width: "85vw",
                margin: "auto auto",
                padding: "1em 0",
              }}
            >
              <div id="gallery-work">
                <div id="gallery-work-contents">
                  <div id="gallery-work-label">
                    <div className="label-data">
                      {students.length > 1 || !work.personPhotoUrl ? (
                        <div className="data-artist-no-thumb"></div>
                      ) : (
                        <div className="data-artist-thumb">
                          <img
                            src={work.personPhotoUrl}
                            alt={`Profile Photo: ${work.personName}`}
                          />
                        </div>
                      )}
                      <div className="label-data-titles">
                        <div className="data-title">{title}</div>

                        <div className="data-artist-name">
                          By {students.join(", ")}
                        </div>
                      </div>
                    </div>
                    <div
                      id="close-alert"
                      className="alert"
                      onClick={props.close}
                    >
                      <AiOutlineCloseCircle
                        size="1.4em"
                        fill="white"
                        title="Close Dialog"
                      />
                    </div>
                  </div>
                  <div className="art-box">
                    {work.categoryName === "Video Game Design" ? (
                      <div className="art-box-work">
                        <div className="art-box-image">
                          <img
                            src={work.assetUrl}
                            alt={`${work.title} by ${work.personName}`}
                          />
                        </div>
                      </div>
                    ) : work.assetType === "video" ? (
                      <div className="art-box-work">
                        <div className="art-box-image">
                          <ReactPlayer
                            url={work.assetUrl}
                            controls
                            playing
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload",
                                  onContextMenu: e => e.preventDefault(),
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    ) : work.portfolioWorks !== null && work.isArt ? (
                      <div className="art-box-work">
                        <div className="art-box-image">
                          {portWork !== {} ? (
                            <div>
                              {portWork.title}
                              <img
                                className="art-box-image"
                                src={portWork.assetUrl}
                                alt={`${portWork.title} by ${portWork.personName}`}
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                className="art-box-image"
                                src={work.portfolioWorks[0].assetUrl}
                                alt={`${work.portfolioWorks[0].title} by ${work.portfolioWorks[0].personName}`}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : work.isArt ? (
                      <div className="art-box-work">
                        <div className="art-box-image">
                          <img
                            className="art-box-image"
                            src={work.assetUrl}
                            alt={`${work.title} by ${work.personName}`}
                          />
                        </div>
                      </div>
                    ) : work.portfolioWorks && work.isWriting ? (
                      <div className="writing-box-work">
                        <Scrollbars
                          autoHide
                          autoHideTimeout={1000}
                          // Duration for hide animation in ms.
                          autoHideDuration={300}
                          autoHeight
                          autoHeightMin={400}
                          autoHeightMax={800}
                          // style={{ height: 600 }}
                        >
                          {portWork ? (
                            <div>
                              <p>{parse(portWork.title)}</p>
                              <p>{parse(portWork.content)}</p>
                            </div>
                          ) : (
                            <div>
                              <p>{parse(work.portfolioWorks[0].title)}</p>
                              {parse(work.portfolioWorks[0].content)}
                            </div>
                          )}
                        </Scrollbars>
                      </div>
                    ) : (
                      <div className="writing-box-work">
                        <Scrollbars
                          autoHide
                          autoHideTimeout={1000}
                          // Duration for hide animation in ms.
                          autoHideDuration={300}
                          autoHeight
                          autoHeightMin={400}
                          autoHeightMax={800}
                          // style={{ height: 600 }}
                        >
                          <p>
                            {parse(work.content)}
                          </p>
                        </Scrollbars>
                      </div>
                    )}

                    <div className="details">
                      <div className="detail-groups scrollbars">
                        <div className="detail-group">
                          <strong>{students.join(", ")}</strong>
                          <ul>
                            {students.length > 1 ? null : (
                              <li>Grade {work.personGrade}</li>
                            )}

                           
                            
                            {schools.map((school, index) => {
                              return (
                                <span key={index}>
                                  <li>{school.name}</li>
                                  <li>{school.address.slice(0, -5)}</li>
                                </span>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="detail-group">
                          {work.teachers.length > 1 ? (
                            <strong>Educators</strong>
                          ) : (
                            <strong>Educator</strong>
                          )}
                          {work.teachers.map((teacher, index) => {
                            return (
                              <ul key={index}>
                                <li>{teacher}</li>
                              </ul>
                            )
                          })}
                        </div>
                        <div className="detail-group">
                          {work.awards.length > 1 ? (
                            <strong>Awards</strong>
                          ) : (
                            <strong>Award</strong>
                          )}
                          <div>{work.categoryName}</div>
                          {work.awards.map((award, index) => {
                            return (
                              <ul key={index}>
                                <li>{award}</li>
                              </ul>
                            )
                          })}
                        </div>
                        {work.portfolioWorks && work.isWriting ? (
                          <div className="detail-group">
                            <strong>Portfolio Works</strong>

                            {work.portfolioWorks.map((portWork, index) => {
                              return (
                                <ul key={index}>
                                  <li>
                                    <span
                                      className="port-thumb-writing"
                                      id={portWork.id}
                                      onClick={handleClick}
                                    >
                                      {portWork.title}
                                    </span>
                                  </li>
                                </ul>
                              )
                            })}
                          </div>
                        ) : null}

                        {work.portfolioWorks && work.isArt ? (
                          <div className="detail-group">
                            <strong>Portfolio Works</strong>
                            <div className="port-thumbs-grid">
                              {work.portfolioWorks.map((portWork, index) => {
                                return (
                                  <div className="port-thumb-image">
                                    <img
                                      key={index}
                                      id={index}
                                      src={portWork.assetUrl}
                                      alt={`${portWork.title} by ${portWork.personName}`}
                                      onClick={handleClickArt}
                                    />
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <Share
                  location={props.location}
                  title={title}
                  students={students}
                />
              </div>
            </DialogContent>
          </DialogOverlay>
        </div>
      )}
    </div>
  )
}

export default SingleWorkRoute
