import React, { useState, useEffect } from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import socialEmail from "../assets/images/common/social-email.svg"

import socialTwitter from "../assets/images/common/social-x-black.svg"

import socialFacebook from "../assets/images/common/social-facebook.svg"


function Share({ location, title, students }) {
  const [openShare, setOpenShare] = useState(false)
  const toggleShare = () => {
    setOpenShare(!openShare)
  }

  // monitor the state of the toggle
  // add/remove click event handler to the document
  useEffect(() => {
    const clickHandler = ({ target }) => {
      const container = document.getElementById(`share-button`)
      if (container.contains(target)) return
      setOpenShare(false)
    }

    document.addEventListener("click", clickHandler)

    // these functions clean up the event listeners
    return () => document.removeEventListener("click", clickHandler)
  })

  // same but for keypresses
  // if the esc key is pressed close the toggles
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 27) return
      setOpenShare(false)
    }
    document.addEventListener("keydown", keyHandler)

    return () => document.removeEventListener("keydown", keyHandler)
  })

  

  const stringStudents = students ? students.join(", ") : null

  const workText = `${title} by ${stringStudents}`

  return (
    <div id="share-button">
      <button to="#" onClick={toggleShare} className="button">
        Share This Page
      </button>
      {openShare ? (
        <div className="menu" style={{ opacity: "1", marginTop: "0px" }}>
          <OutboundLink
            href={`mailto:?subject=${workText}&body=${location.href}`}
            className="share-menu-email"
          >
            <span style={{ backgroundImage: "url(" + socialEmail + ")" }} ></span>
          </OutboundLink>

          <OutboundLink
            href={`http://www.facebook.com/share.php?u=${location.href}`}
            target="_blank"
            className="share-menu-facebook"
          >
            <span style={{ backgroundImage: "url(" + socialFacebook + ")" }}></span>
          </OutboundLink>

          <OutboundLink
            href={`https://twitter.com/intent/tweet?text=${workText}&url=${location.href}`}
            target="_blank"
            className="share-menu-twitter"
          >
            <span style={{ backgroundImage: "url(" + socialTwitter + ")" }}></span>
          </OutboundLink>
        </div>
      ) : null}
    </div>
  )
}

export default Share
