import React, { Component } from "react"

import LoadingSpinner from "../loading-spinner"
import GalleryCard from "./gallery-card"
import GalleryEndpoints from "./gallery-data"
import GoldPortWriting from "../../assets/data/writingPortsGold"
import GoldPortSilverDistinctWriting from "../../assets/data/writingPortsSilverDistinction"

let randomPage = 1

const writingColorArray = [
  "rgba(69, 148, 61, 1)",
  "rgba(243, 120, 54, 1)",
  "rgba(229, 81, 45, 1)",
  "rgba(46, 146, 153, 1)",
  "rgba(46, 147, 154, 1)",
  "rgba(44, 132, 201, 1)"
]

export default class GalleryGrid extends Component {
  state = {
    loading: false,
    works: [],
    page: '',
    totalPages: ''
  }

  getWorks = async query =>
    await fetch(process.env.GATSBY_ORS_GALLERY + query)
      .then(res => res.json())
      .catch(error => console.log("error", error))

  nextPage = (props) => {
 
      this.setState({ loading: true }, () => {
        let galleryQuery = ""
        const worksArray = []
        const concatCat = this.props.category.split(" ").join("")
     
        galleryQuery =
       `?grm.${this.props.workType}=${this.props.query}&grm.year=${this.props.year}&grm.page=` +
        String(this.state.page + 1)
      
        fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
          .then(response => response.json())
          .then(responseJson => {
         
              let current_page = responseJson.pagination.page
              let all_pages = responseJson.pagination.totalPages
              let i = 0
              for (i = 0; i < responseJson.works.length; i++) {
                worksArray.push(responseJson.works[i])
              }
              this.setState({ works: worksArray, page: current_page, total_pages: all_pages, loading: false })
          })
          .catch(error => {
              console.log("error", error)
          })
      })
  }

    prevPage = () => {
           this.setState({ loading: true }, () => {
        let galleryQuery = ""
        const worksArray = []
        const concatCat = this.props.category.split(" ").join("")
     
        galleryQuery =
        `?grm.${this.props.workType}=${this.props.query}&grm.year=${this.props.year}&grm.page=` +
        String(this.state.page - 1)
      
        fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
          .then(response => response.json())
          .then(responseJson => {
             
              let current_page = responseJson.pagination.page
              let all_pages = responseJson.pagination.totalPages
              let i = 0
              for (i = 0; i < responseJson.works.length; i++) {
                worksArray.push(responseJson.works[i])
              }

              this.setState({ works: worksArray, page: current_page, total_pages: all_pages, loading: false })
          })
          .catch(error => {
              console.log("error", error)
          })
      })
  }

  GenerateGrid = () => {
    this.setState({ loading: true }, () => {
     
      let galleryQuery = ""
      const worksArray = []
      if (this.props.category) {
        const concatCat = this.props.category.split(" ").join("")
      
        galleryQuery =
            `?grm.${this.props.workType}=${this.props.query}&grm.year=2023&grm.page=` +
          String(randomPage)

        fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
          .then(response => response.json())
          .then(responseJson => {
     
            let i = 0
            for (i = 0; i < responseJson.works.length; i++) {
              worksArray.push(responseJson.works[i])
            }

            this.setState({ works: worksArray, loading: false })
          })

          .then((randomPage += 1))
          .catch(error => {
            console.log("error", error)
          })
      } else if (this.props.year) {
        galleryQuery =
          `/random?r.art_number=8&r.writing_number=8&r.year=${this.props.year}&r.page=` +
          String(randomPage)


        fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
          .then(response => response.json())
          .then(responseJson => {
    
            let i = 0
            for (i = 0; i < 2; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 2; i < 4; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
            for (i = 4; i < 6; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 6; i < 8; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
     

            this.setState({ works: worksArray, loading: false })
          })

          .then((randomPage += 1))
          .catch(error => {
            console.log("error", error)
          })
      } else {
        galleryQuery =
          `/random?r.art_number=8&r.writing_number=8&r.year=2023&r.page=` +
          String(randomPage)


        fetch(process.env.GATSBY_ORS_GALLERY + galleryQuery)
          .then(response => response.json())
          .then(responseJson => {
    
            let i = 0
            for (i = 0; i < 2; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 2; i < 4; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
            for (i = 4; i < 6; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 6; i < 8; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
     

            this.setState({ works: worksArray, loading: false })
          })

          .then((randomPage += 1))
          .catch(error => {
            console.log("error", error)
          })
      }
    })
  }

  componentDidMount() {



    this.setState({ loading: true }, () => {

      const worksArray = []

      let galleryQuery = ""

      let queryYear = this.props.year ? this.props.year : 2023

      if (this.props.category && this.props.query === "BPA") {
        galleryQuery = `?grm.year=${queryYear}&grm.art_portfolio=true&grm.writing_portfolio=false&grm.${this.props.workType}=B`
      }  else if (this.props.category && this.props.query === "DA") {  
        galleryQuery = `?&grm.year=${queryYear}&grm.art_portfolio=true&grm.writing_portfolio=false&grm.${this.props.workType}=D`
      }  else if (this.props.category && this.props.query === "BPW") {  
        galleryQuery = `?&grm.year=${queryYear}&grm.art_portfolio=false&grm.writing_portfolio=true&grm.${this.props.workType}=B`
      }  else if (this.props.category && this.props.query === "DW") {  
        galleryQuery = `?&grm.year=${queryYear}&grm.art_portfolio=false&grm.writing_portfolio=true&grm.${this.props.workType}=D`
      } else if (this.props.category === "Expanded Projects") {  
        galleryQuery = `?&grm.year=${queryYear}&grm.art=Expanded%20Projects&grm.future_new=true`
      } else if (this.props.category) {  
        galleryQuery = `?&grm.year=${queryYear}&grm.${this.props.workType}=${this.props.query}`
      } else {
        galleryQuery = GalleryEndpoints.galleryRandom
      }

      if (this.props.category) {
        this.getWorks(galleryQuery).then(responseJson => {
          let current_page = responseJson.pagination.page
          let all_pages = responseJson.pagination.totalPages
            this.setState({ works: responseJson.works, page: current_page, total_pages: all_pages, loading: false})
          })
        } else {
          this.getWorks(galleryQuery).then(responseJson => {
    
            let i = 0
            for (i = 0; i < 2; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 2; i < 4; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
            for (i = 4; i < 6; i++) {
              worksArray.push(responseJson.arts.works[i])
              worksArray.push(responseJson.writings.works[i])
            }
            for (i = 6; i < 8; i++) {
              worksArray.push(responseJson.writings.works[i])
              worksArray.push(responseJson.arts.works[i])
            }
     
            this.setState({ works: worksArray, loading: false })
          })
        }

    })

  }

  render() {
    const { GenerateGrid } = this.props
    const { nextPage } = this.props
    const { prevPage } = this.props

    return (
      <section >
        <div className="gallery-widget">

{/* <h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3> */}

          {this.state.loading ? (
            <LoadingSpinner />
          ) : (
            <ul>
              {this.state.works.map((work, index) => {
                const writingColor =
                  writingColorArray[
                    Math.floor(Math.random() * writingColorArray.length)
                  ]

                return (
                  <GalleryCard
                    key={work.id}
                    work={work}
                    writingColor={writingColor}
                  />
                )
              })}
            </ul>
          )}

          {this.state.total_pages === 0 ? (
            <div className="pagination">
              <h2>No Results </h2> </div> ):
          this.props.category && this.state.total_pages === 1 ? (
            <div className="pagination">
              <p>Page  <span>{this.state.page}</span>  of  <span>{this.state.total_pages}</span></p>
            </div>) :
            this.props.category && this.state.page === 1  ? (
            <div className="pagination">
              <p>Page  <span>{this.state.page}</span>  of  <span>{this.state.total_pages}</span></p>
              <a
                href="#"
                className="button"
                onClick={this.nextPage.bind(this)}
              >
                Next
              </a>
            </div>) :
            this.props.category && this.state.page === this.state.total_pages  ? (
            <div className="pagination">
              <a
                href="#"
                className="button"
                onClick={this.prevPage.bind(this)}
              >
                Previous
              </a>
              <p>Page  <span>{this.state.page}</span>  of  <span>{this.state.total_pages}</span></p>
            </div>) :
            this.props.category ? (
            <div className="pagination">
              <a
                href="#"
                className="button"
                onClick={this.prevPage.bind(this)}
              >
                Previous
              </a>
              <p>Page  <span>{this.state.page}</span>  of  <span>{this.state.total_pages}</span></p>
              <a
                href="#"
                className="button"
                onClick={this.nextPage.bind(this)}
              >
                Next
              </a>
            </div>
          ) : 
          (
            <div className="pagination">
              <a
                href="#"
                className="button"
                onClick={() => this.GenerateGrid()}
              >
                See More
              </a>
            </div>
          ) 
        }
        </div>
      </section>
    )
  }
}
