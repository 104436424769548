import React, { Component } from "react"
import { Link } from "gatsby"

import imgArchitecture from "../../assets/images/gallery-icons/gallery-art-architecture.jpg"
import imgCeramics from "../../assets/images/gallery-icons/gallery-art-ceramics.jpg"
import imgComic from "../../assets/images/gallery-icons/gallery-art-comic.jpg"
import imgDesign from "../../assets/images/gallery-icons/gallery-art-design.jpg"
import imgDigital from "../../assets/images/gallery-icons/gallery-art-digital.jpg"
import imgDrawing from "../../assets/images/gallery-icons/gallery-art-drawing.jpg"
import imgEditorial from "../../assets/images/gallery-icons/gallery-art-editorial.jpg"
import imgExpanded from "../../assets/images/gallery-icons/gallery-art-expanded.jpg"
import imgFashion from "../../assets/images/gallery-icons/gallery-art-fashion.jpg"
import imgFilm from "../../assets/images/gallery-icons/gallery-art-film.jpg"
import imgJewelry from "../../assets/images/gallery-icons/gallery-art-jewelry.jpg"
import imgMixed from "../../assets/images/gallery-icons/gallery-art-mixed.jpg"
import imgPainting from "../../assets/images/gallery-icons/gallery-art-painting.jpg"
import imgPhotography from "../../assets/images/gallery-icons/gallery-art-photography.jpg"
import imgPrintmaking from "../../assets/images/gallery-icons/gallery-art-printmaking.jpg"
import imgSculpture from "../../assets/images/gallery-icons/gallery-art-sculpture.jpg"

export default class CategoryArt extends Component {



  render() {

    const CATEGORIES = [
        {
          icon: imgArchitecture,
          title: "Architecture & Industrial Design",
          query: "Architecture%20%26%20Industrial Design",
        },
        {
          icon: imgCeramics,
          title: "Ceramics & Glass",
          query: "Ceramics%20%26%20Glass",
        },
        {
          icon: imgComic,
          title: "Comic Art",
          query: "Comic Art",
        },
        {
          icon: imgDesign,
          title: "Design",
          query: "Design",
        },
        {
          icon: imgDigital,
          title: "Digital Art",
          query: "Digital Art",
        },
        {
          icon: imgDrawing,
          title: "Drawing & Illustration",
          query: "Drawing%20%26%20Illustration",
        },
        {
          icon: imgEditorial,
          title: "Editorial Cartoon sponsored by The Herb Block Foundation",
          query: "Editorial Cartoon sponsored by The Herb Block Foundation",
        },
        {
          icon: imgExpanded,
          title: "Expanded Projects",
          query: "Expanded Projects",
        },
        {
          icon: imgFashion,
          title: "Fashion",
          query: "Fashion",
        },
        {
          icon: imgFilm,
          title: "Film & Animation",
          query: "Film%20%26%20Animation",
        },
        {
          icon: imgJewelry,
          title: "Jewelry",
          query: "Jewelry",
        },
        {
          icon: imgMixed,
          title: "Mixed Media",
          query: "Mixed Media",
        },
        {
          icon: imgPainting,
          title: "Painting",
          query: "Painting",
        },
        {
          icon: imgPhotography,
          title: "Photography",
          query: "Photography",
        },
        {
          icon: imgPrintmaking,
          title: "Printmaking",
          query: "Printmaking",
        },
        {
          icon: imgSculpture,
          title: "Sculpture",
          query: "Sculpture",
        },
      ]
      
    return (
      <div id="gallery">
{ /*<h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3>*/}
        <h2>Art Categories</h2>
       
        <section id="gallery-categories">
          <ul>
            {CATEGORIES.map((cat, index) => (
              <li key={index}>
              
                <Link to="/gallery" state= {{ workType: "art", category: cat.title, query: cat.query, year: "2023" }}>
                  <span className="thumb">
                    <img
                      src={cat.icon}
                      width="120"
                      height="120"
                      alt={cat.title}
                    />
                  </span>
                  <span className="name">{cat.title}</span>
                  </Link>
                
              </li>
            ))}
          </ul>
        </section>
      </div>
    )
  }
}



