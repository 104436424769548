import React, { useState } from "react"
import { Router, Link, Location } from "@reach/router"
import { DialogOverlay, DialogContent } from "@reach/dialog"

import "@reach/dialog/styles.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

import GalleryContainer from "../components/gallery/gallery-container"
import GalleryWork from "../components/gallery/single-work"
import GalleryWorkRoute from "../components/gallery/single-work-route"
import CategoryArt from "../components/gallery/art"
import CategoryWriting from "../components/gallery/writing"
import CategoryAwards from "../components/gallery/awards"
import GallerySearch from "../components/gallery/search"

function Routes(props) {

  return (
    <Router {...props}>

      <GalleryContainer path="/gallery/" />
      <CategoryArt path="/gallery/art" />
      <CategoryWriting path="/gallery/writing" />
      <CategoryAwards path="/gallery/awards" />
      <GallerySearch path="/gallery/search" />
      {props.location.key !== "initial" ? (
        <GalleryWork path="/gallery/:id" close={props.close} />
      ) : (
        <GalleryWorkRoute path="/gallery/:id" close={props.close} />
      )}
    </Router>
  )
}

const Gallery = ({ data }) => {


  const [navSection, setnavSection] = useState(false)
  const toggleSection = () => {
    setnavSection(!navSection)
  }

  return (
    <Location>
      {({ location, navigate }) => {
        const { oldLocation } = location.state || {}
        const close = () => {
          location.key !== "initial"
            ? navigate(oldLocation.pathname)
            : navigate("/gallery")
        }

        return (
          <Layout toggleSection={toggleSection}>
            <Seo title="Gallery" description="Browse Award Winning Works." />
            <nav
              id="nav-section"
              role="navigation"
              aria-label="Gallery Menu"
              style={navSection ? { display: "block" } : null}
            >
              <ul>
                <li>
                  <Link to="/gallery/art">Art Categories</Link>
                </li>
                <li>
                  <Link to="/gallery/writing">Writing Categories</Link>
                </li>
                <li>
                  <Link to="/gallery/awards">By Award</Link>
                </li>
                <li>
                  <Link to="/gallery/search">Filter & Search</Link>
                </li>
              </ul>
            </nav>

            <Routes
              location={oldLocation != null ? oldLocation : location}
              close={close}
            />
            <DialogOverlay
              isOpen={oldLocation != null}
              onDismiss={close}
              aria-label="Single Award Work Dialog."
              style={{ background: "hsla(0, 0%, 0%, 0.85)", zIndex: 1 }}
            >
              <DialogContent
                aria-label="Single Work Dialog."
                style={{
                  background: "none",
                  width: "85vw",
                  margin: "auto auto",
                  padding: "1em 0",
                }}
              >
                <Routes location={location} close={close} />
              </DialogContent>
            </DialogOverlay>
          </Layout>
        )
      }}
    </Location>
  )
}

export default Gallery
