import React, { Component } from 'react'
import { Link } from "gatsby"
import imgCritical from "../../assets/images/gallery-icons/gallery-writing-critical.png"
import imgDramatic from "../../assets/images/gallery-icons/gallery-writing-dramatic.png"
import imgFlashFiction from "../../assets/images/gallery-icons/gallery-writing-flashfiction.png"
import imgHumor from "../../assets/images/gallery-icons/gallery-writing-humor.png"
import imgJournalism from "../../assets/images/gallery-icons/gallery-writing-journalism.png"
import imgNovel from "../../assets/images/gallery-icons/gallery-writing-novel.png"
import imgPersonal from "../../assets/images/gallery-icons/gallery-writing-personal.png"
import imgPoetry from "../../assets/images/gallery-icons/gallery-writing-poetry.png"
import imgSciFi from "../../assets/images/gallery-icons/gallery-writing-scifi.png"
import imgShortStory from "../../assets/images/gallery-icons/gallery-writing-shortstory.png"




export default class CategoryWriting extends Component {

    
    render() {

        const CATEGORIES = [ {
            icon: imgCritical,
            title: "Critical Essay",
            query: "Critical Essay"
            },
            {
            icon: imgDramatic,
            title: "Dramatic Script",
            query: "Dramatic Script"
            },
            {
            icon: imgFlashFiction,
            title: "Flash Fiction",
            query: "Flash Fiction"
            },
            {
            icon: imgHumor,
            title: "Humor",
            query: "Humor"
            },
            {
            icon: imgJournalism,
            title: "Journalism",
            query: "Journalism"
            },
            {
            icon: imgNovel,
            title: "Novel Writing",
            query: "Novel Writing"
            },
            {
            icon: imgPersonal,
            title: "Personal Essay & Memoir",
            query: "Personal Essay%20%26%20Memoir"
            },
            {
            icon: imgPoetry,
            title: "Poetry",
            query: "Poetry"
            },
            {
            icon: imgSciFi,
            title: "Science Fiction & Fantasy",
            query: "Science Fiction%20%26%20Fantasy"
            },
            {
            icon: imgShortStory,
            title: "Short Story",
            query: "Short Story"
            }
            ]


        return (
                <div id="gallery">
{ /*<h3 class="alert" id="alert">🚧  The gallery is currently unavailable due to technical maintenance 🚧 <br />
           Thank you for your patience.</h3>*/}

<h2>Writing Categories</h2>

       
          <section id="gallery-categories">
          <ul>
          {CATEGORIES.map((cat, index) => (
              <li key={index}>
              
                <Link to="/gallery" state={{ workType: "writing", category: cat.title, query: cat.query, year: "2023" }}>
                  <span className="thumb">
                    <img
                      src={cat.icon}
                      width="120"
                      height="120"
                      alt={cat.title}
                    />
                  </span>
                  <span className="name">{cat.title}</span>
                </Link>
                
              </li>
            ))}
          </ul>
        </section>
        
            </div>
        )
    }
}

